export const NL = {
  Lang: "NL",
  Upload_logo_here: "Upload hier logo",
  Setting: "Instellingen",
  Log_out: "Uitloggen",
  Customer: "Klant",
  User: "Gebruiker",
  Super_admin:"Super beheerder",
  Management: "Beheer",
  Permissions: "Rechten",
  Style: "Stijl",
  Invoicing: "Facturering",
  Hello: "Hallo",
  Welcome_note: "Welkom bij de IQB",
  Customer_welcome_content:
    "Klant account: IQB is een tool die handige formulieren beschikbaar stelt zoals (K)FML online om de vragen over de belastbaarheid en/of beperkingen van een verzekerde werknemer in te vullen. Vervolgens kunt u de rapportage genereren en gebruiken in uw eindrapportage. U kunt in uw klant account gebruikers en hun permissies inregelen",
  Admin_welcome_content:
    "Admin-account: IQB is een tool die handige formulieren beschikbaar stelt zoals (K)FML online om de vragen over de belastbaarheid en/of beperkingen van een verzekerde werknemer in te vullen.",
  User_welcome_content:
    "IQB is een tool die handige formulieren beschikbaar stelt zoals (K)FML online om de vragen over de belastbaarheid en/of beperkingen van een verzekerde werknemer in te vullen. Vervolgens kunt u de rapportage genereren en gebruiken in uw eindrapportage",
  Note: "Opmerking: er worden geen gegevens opgeslagen tijdens het beantwoorden van de vragen en het opstellen van het rapport als pdf-document",
  User_Management: "Gebruikersbeheer",
  Search_user_placeholder: "Gebruikers zoeken op naam/e-mail....",
  Search_customer_placeholder: "Klanten zoeken...",
  Add_Existing_User: "Gebruiker herstellen",
  Add_user: "Voeg gebruiker toe",
  Email: "E-mail",
  Add: "Toevoegen",
  Status: "Toestand",
  Start_date: "Startdatum",
  Not_existing_user: "Er zijn geen bestaande gebruikers!",
  Remove: "Verwijderen",
  End_date: "Einddatum",
  Customers: "Klanten",
  Actions: "Acties",
  Deleted_successfully: "Succesvol verwijderd",
  Something_went_wrong:"Er is iets fout gegaan",
  Edit: "Bewerk",
  View: "Visie",
  Delete: "Verwijderen",
  Active: "Actief",
  Inactive: "Inactief",
  First_name: "Voornaam",
  Last_name: "Achternaam",
  Search: "Zoeken",
  Submit: "Indienen",
  customer_name: "klantnaam",
  Searchable: "Doorzoekbaar",
  Phone_No: "Telefoon nr.",
  Email_address: "E-mailadres",
  Name: "Naam",
  Questionnaire: "Vragenlijst",
  Font_family: "Lettertype",
  Font_size: "Formaat",
  Primary_color: "Primaire kleur",
  Secondary_color: "Secundaire kleur",
  Support_format: "Ondersteunde formaten: png, svg, jpg, max. 300kb",
  Support_format_pdf: "Ondersteunde formaten: png, jpg, max. 300kb",
  Save: "Opslaan",
  Title_color: "Titel kleur",
  Header_color: "Koptekst kleur",
  Background_color: "Achtergrond kleur",
  Admin: "beheerder",
  User_access_management: "Beheer van gebruikerstoegang",
  Search_customer: "Klanten zoeken...",
  Date_time: "Datum Tijd",
  Company: "Bedrijf",
  Fml_file_no: "Fml unieke nr",
  Quick_links: "Snelle links",
  Top: "Top",
  End: "Einde",
  Basic_info: "Algemene info",
  No_of_user: "Aantal gebruikers",
  Business_Name: "Bedrijfsnaam",
  Search_customer_by_name_email: "Zoek klanten op naam/e-mail....",
  Add_new_customer: "Nieuwe klant toevoegen",
  Add_Existing_Customer: "Klant herstellen",
  Type: "Type",
  Expire: "Verlopen",
  Mobile: "Mobiel",
  Short_Name: "Korte naam",
  Address: "Adres",
  Zip_Code: "Postcode",
  Residance: "residentie",
  Country: "Land",
  Billing_Email: "Rekening e-mail",
  Phone: "Telefoon",
  Discount: "Korting",
  Please_enter_valid_short_name: "Voer een geldige korte naam in",
  Short_name_is_available: "Korte naam is beschikbaar",
  Short_name_has_already_been_used: "Korte naam is al gebruikt.",
  Toggle: "Schakelaar",
  Admin: "Beheerder",
  Add_new_questionnaire: "Nieuwe vragenlijst toevoegen",
  Questionnaire_Access_Management: "Vragenlijst Toegangsbeheer",
  Select_user: "Selecteer gebruiker",
  Questionnaires: "Vragenlijsten",
  Questions: "Vragen",
  Add_new_question: "Nieuwe vraag toevoegen",
  Questionnaire_name_goes_here: "De naam van de vragenlijst komt hier...",
  Category: "Categorie",
  Download_pdf: "Downloaden PDF",
  Download_doc: "Downloaden DOC",
  Change_password: "Verander wachtwoord",
  Current_password: "huidig ​​wachtwoord",
  New_password: "nieuw ​​wachtwoord",
  Confirm_new_password: "bevestig nieuw wachtwoord",
  Enter: "Binnenkomen",
  Select_the_retention_period:"Selecteer bewaartermijn",
  Select_date:"Selecteer datum",
  Invalid_email_address: "Ongeldig e-mailadres",
  Please_enter: "Kom binnen alstublieft",
  valid_first_name: "geldige voornaam",
  valid_last_name: "geldige achternaam",
  valid_phone_number: "geldig telefoonnummer",
  Enter_your_name: "Vul uw naam in",
  Enter_your_lastname: "Voer uw achternaam in",
  Enter_your_number: "Voer uw nummer in",
  Enter_your_email: "Voer uw e-mailadres in",
  Password_strength_text:
    "Het wachtwoord moet minimaal 8 en maximaal 20 tekens lang zijn, minimaal een kleine letter, hoofdletter, cijfer en een speciaal teken zonder spaties bevatten",
  Password_does_not_match: "Wachtwoord komt niet overeen",
  First_name_is_required: "voornaam is verplicht",
  Last_name_is_required: "Achternaam is verplicht",
  Phone_no_is_required: "Telefoon nr. Is verplicht",
  Email_is_required: "E-mail is verplicht",
  Email_invalid: "E-mail ongeldig",
  Start_date_is_required: "Startdatum is verplicht",
  Searchable_is_required: "Doorzoekbaar is verplicht",
  Customer_name_is_required: "Klantnaam is verplicht",
  Please_enter_a_valid_kvk: "Voer een geldig kvk in",
  Select_a_type: "Selecteer een soort",
  Business_name_is_required: "Bedrijfsnaam is verplicht",
  Address_is_required: "adres is nodig",
  Residence_address_is_required: "Woonadres is verplicht",
  Please_enter_a_valid_country_name: "Voer een geldige land in",
  End_date_is_required: "Einddatum is verplicht",
  Signed_out_successfully: "Succesvol uitgelogd",
  User_settings_update_successfully: "Gebruikersinstellingen zijn bijgewerkt",
  Password_changed_successfully: "Wachtwoord succesvol bijgewerkt",
  Record_recovered_successfully: "Record hersteld",
  Record_deleted_successfully: "Opname succesvol verwijderd",
  User_add_successfully: "Gebruiker succesvol toegevoegd",
  User_updated_successfully: "Gebruikersgegevens zijn bijgewerkt",
  Customer_updated_successfully: "Klant geüpdatet",
  Customer_added_successfully: "Klant succesvol toegevoegd",
  Data_updated_successfully: "Gegevens succesvol bijgewerkt",
  Data_submitted_successfully: "Gegevens succesvol verzonden",
  Customer_permission_updated_successfully: "Klantrechten zijn geüpdatet",
  User_permission_updated_successfully: "Gebruikersrechten zijn bijgewerkt",
  Customer_style_updated: "Klant IQB-stijl bijgewerkt",
  Customer_style_deleted: "Stijl succesvol verwijderd",
  Customer_pdf_style_updated: "Klant PDF-stijl bijgewerkt",
  File_saved_succeessfully: "Bestand succesvol opgeslagen",
  PDF_file_downloaded_successfully: "PDF-bestand succesvol gedownload",
  DOC_file_downloaded_successfully: "DOC-bestand succesvol gedownload",
  Are_you_sure_you_want_to_delete: "Verwijderen! Weet u zeker?",
  Select_at_least_one_user: "Selecteer minimaal één gebruiker.",
  Email_already_exist: "Email bestaat al",
  Record_updated_successfully: "Record succesvol bijgewerkt",
  Please_fill_the_form_first: "Vul dan eerst het formulier in",
  File_no_is_required: "Dossiernr is verplicht!",
  Please_select_the_date: "Selecteer de datum",
  Selected_successfully: "Geselecteerd",
  To_FML: "Naar FML",
  To_KFML: "Naar KFML",
  Is_required: "is verplicht!",
  Updated_successfully: "Succesvol geupdatet",
  Subject: "Onderwerp",
  Message_Body: "berichttekst",
  Update: "Bijwerken",
  Email_template: "E-mail resetten",
  Message_body_required: "Berichttekst is verplicht",
  Subject_required: "Onderwerp is verplicht",
  Login: "Log in",
  LoginPara: "Gebruik uw e-mailadres om door te gaan met IQB",
  Password: "Wachtwoord",
  Remember_me: "Onthoud me?",
  Not_access_yet: "Nog geen toegang?",
  Forgot_password: "Wachtwoord vergeten?",
  All_right_reserved: "Alle rechten voorbehouden.",
  Built_with: "Gebouwd met",
  Achieving_more_with_less: "Meer bereiken met minder",
  Quick_and_easy_form_obligation: "Snel en eenvoudig formulierplicht",
  Reset_Password: "Wachtwoord opnieuw instellen",
  Reset_Password_Para: "Voer het e-mailadres in en we sturen u een link om uw wachtwoord opnieuw in te stellen.",
  Send: "Versturen",
  Back_to: "Terug naar",
  Enter_your_password: "Voer uw wachtwoord in",
  Please_enter_email_address: "Voer het e-mailadres in",
  Please_enter_password: "Voer wachtwoord in",
  Reset_link_sent_successfully: "Resetlink succesvol verzonden!",
  Reset_content:
    "Link voor wachtwoord reset succesvol verzonden naar uw e-mailadres, klik op de link om uw wachtwoord te resetten!",
  Go_to: "Ga naar",
  Confirm_password: "Bevestig wachtwoord",
  Enter_your_password: "Voer uw wachtwoord in",
  Enter_confirm_password: "Vul wachtwoord bevestigen in",
  Password_does_not_match: "Wachtwoord komt niet overeen",
  Enter_email_addr_content:
    "Voer het e-mailadres in en we sturen u een link om te resetten wachtwoord.",
  Navigations: "Menu",
  Customize_the_questionnaire: "Pas de vragenlijst aan",
  Is_this_questionnaire_type_FML: "Is deze vragenlijst van het type FML?",
  Do_Uncategorized_section_visible_on_frontend:
    "Is de sectie Uncategorized zichtbaar op de frontend?",
  Enter_FML_title: "Voer FML-titel in",
  Sign_in_paragraph: "Gebruik uw e-mailadres om door te gaan met IQB",
  Customizations: "Aanpassingen",
  Request_access: "Toegang aanvragen",
  Request_access_para: "Meld u aan voor een proefperiode",
  Register_customer_message:
    "U bent geregistreerd. Check uw e-mail voor de inloggegevens!",
  Register: "Registreer",
  Trial_expired: "Trial account is verlopan!",
  Subs_expired: "Abonnement verlopen!",
  Sidenav_subscription_left: "Uw abonnement loopt af in",
  Sidenav_trial_left: "Overgebleven voor uw proefperiode",
  Trial_will_expire: "De proefperiode loopt af over",
  Subscription_will_expire: "Abonnement loopt af in",
  Sign_up: "Inschrijven",
  Sign_in: "Log in",
  Have_an_account: "Een account hebben?",
  Sign_up_for_trial_period: "Aanmelden voor proefperiode",
  Registration_mail:"Registratie mail",
  Home: "Home",
  About_us: "Over ons",
  Forms: "Forms",
  Contact_us: "Contact us",
  Terms_and_conditions: "Voorwaarden",
  Privacy_policy: "Privacybeleid",
  Cookie_policy: "Cookie beleid",
  Lets_connect: "Laten we verbinding maken"
};
